import { Box, CircularProgress, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useNavigate, useParams } from 'react-router';
import React, { useEffect } from 'react';
import useGetEvaluationDetail from '../../../Evaluations/hooks/useGetEvaluationDetail';
import { RootStoreType } from '../../../../redux/store.type';
import { useAppSelector } from '../../../../redux/store';
import useGetAccount from '../../../Assessments/hooks/useGetAccount';

// import { EvaluationActions, EvaluationStatus } from '../../../type';
import usePutAssessmentStatus from '../../hooks/usePutAssessmentStatus';
import { AccountSettingsUser } from '../../../Accounts/AccountSettings/AccountSettings';
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmBox';
import translation from '../../../../locales/en/translation.json';
import {
    SaveButton,
    CancelButton,
} from '../../../Accounts/CreateClient/CreateClient.styles';
import useAccountSettingsUsers from '../../../Accounts/hooks/useGetAccountSettingsUsers';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../Accounts/reducers/getAccountSetting';

import AssessmentDetailsSummary from './Summary/AssessmentDetailsSummary';
import { CustomAccountBranding } from '../../../Branding/type';
import useGetAssessmentDetails from '../../hooks/useGetAssessmentDetails';
import AssessmentDetailHeaderCard from './AssessmentDetailHeaderCard';
import { clearAssessmentDetails } from '../../reducers/getAssessmentDetails';
import { AssessmentStatus, UpsertAssessmentPayload } from '../../type';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import useDeleteAssessment from '../../hooks/useDeleteAssessment'
import AssessmentAnalysis from './Analysis/AssessmentAnalysis';

interface AssessmentDetailsViewProps {
    branding: CustomAccountBranding;
    id: string;
}

const AssessmentDetailsView: React.FC<AssessmentDetailsViewProps> = ({ branding, id }) => {
    const [value, setValue] = React.useState('1');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openConfirmBox, setOpenConfirmBox] = React.useState(false);

    const getAccountResponse = useGetAccount();
    const getAccountSettingsUsers = useAccountSettingsUsers();
    const getAssessmentDetailsReducer = useGetAssessmentDetails();
    const { loading, editAssessmentStatus } = usePutAssessmentStatus();
    const { deleteAssessmentById } = useDeleteAssessment();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const assessmentDetail = useAppSelector((state) => {
        return state.assessmentReducers.getAssessmentDetailsReducer?.assessment_details;
    });

    React.useEffect(() => {
        if (!loading) {
            dispatch(clearAssessmentDetails());
            getAssessmentDetailsReducer.getDetails(id || '');
        }
    }, [loading, id]);

    const accountUserData: AccountSettingsUser[] | null = useAppSelector((state: RootStoreType) => {
        return state.allClientReducer.accountSettingReducer.accountSetting;
    });

    const accountInformation = useAppSelector((state: RootStoreType) => {
        return state.assessmentReducers.accountInfoReducer.accountDetail || null;
    });

    React.useEffect(() => {
        if (id) {
            getAssessmentDetailsReducer.getDetails(id);
        }
    }, []);

    React.useEffect(() => {
        if (assessmentDetail?.account?.id) {
            getAccountResponse.getAccount(`${assessmentDetail.account.id}`);
            getAccountSettingsUsers.getAccountSettingsUsers(`${assessmentDetail.account.id}`);
        }
    }, [assessmentDetail]);

    return !accountInformation ||
        !accountUserData ||
        !assessmentDetail ||
        getAccountResponse.loading ||
        getAccountResponse.error ||
        loading ||
        getAssessmentDetailsReducer.loading ||
        getAssessmentDetailsReducer.error ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
    ) : (
        <>
            {openConfirmBox && (
                <ConfirmationBox
                    doOpen={openConfirmBox}
                    deleteRecord={() => {
                        if (assessmentDetail.uuid) {
                            deleteAssessmentById(assessmentDetail.uuid);
                        }
                    }}
                    doClose={() => {
                        setOpenConfirmBox(false);
                    }}
                    confirmationMessage={translation.evaluation_delete_confirm_text}
                />
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '20px',
                    paddingBottom: '20px',
                }}
            >
                <SaveButton
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Back
                </SaveButton>
                <SaveButton
                    onClick={() => {
                        dispatch(resetState());
                        navigate(`/edit-evaluation-assessment/${id as string}`);
                    }}
                >
                    Edit
                </SaveButton>
            </Box>
            <Box sx={{ padding: '8px', marginBottom: '10px' }}>
                <Typography
                    sx={{
                        color: '#1C1C1C',
                        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '20px',
                    }}
                >
                    {branding?.evaluation_property_name_translation} Details
                </Typography>
            </Box>
            <AssessmentDetailHeaderCard
                reRender={openConfirmBox}
                assessmentDetail={assessmentDetail}
                updateStatus={(status: AssessmentStatus) => {
                    if (status !== AssessmentStatus.DELETE) {
                        if (assessmentDetail) {
                            if (!loading) {
                                editAssessmentStatus(assessmentDetail.uuid, status);
                            }
                        }
                    } else {
                        setOpenConfirmBox(true);
                    }
                }}
            />

            <TabContext value={value}>
                <Box>
                    <TabList
                        sx={{
                            '& .MuiTab-root': {
                                '&.Mui-selected': {
                                    color: '#00DB99',
                                },
                                textTransform: 'none',
                            },
                        }}
                        onChange={handleChange}
                        TabIndicatorProps={{ style: { backgroundColor: '#00DB99' } }}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Summary" value="1" />
                        {assessmentDetail.response_count > 0 && (
                            <Tab label="Analysis" value="2" />
                        )}
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: '0' }} value="1">
                    <AssessmentDetailsSummary
                        accountInformation={accountInformation!}
                        assessmentDetail={assessmentDetail!}
                        accountUserData={accountUserData!}
                    />
                </TabPanel>
                <TabPanel sx={{ padding: '0' }} value="2">
                    <AssessmentAnalysis assessmentDetail={assessmentDetail} />
                </TabPanel>
            </TabContext>
        </>
    );
};

export default AssessmentDetailsView;
