import { Box, Grid, Paper, TextField } from '@mui/material';
import React from 'react';
import {
    Typography19px600,
    Typography12px400,
    CommonTypography,
} from '../../../../../styles/Global.styles';
import { SaveButton } from '../../../../Accounts/CreateClient/CreateClient.styles';
import { store, useAppSelector } from '../../../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { AssessmentOverviewDetails, QuestionCategoryResult } from '../../../type';
import BarChart from './BarChart';
import DistributionGraph from './DistributionGraph';
import ChallengesAndOpportunitiesComponent from './ChallengesAndOpportunities';
import { clearAssessmentDetails } from '../../../reducers/getAssessmentDetails'

const AssessmentAnalysis = ({ assessmentDetail }: { assessmentDetail: AssessmentOverviewDetails }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const createEvaluationQuestionCategories = (assessmentDetail: AssessmentOverviewDetails) => {
        const evaluationQuestionCategories = assessmentDetail.assessment_definition.question_categories.map(category => {
            return {
                id: category.id,
                category: category.name
            };
        });

        return evaluationQuestionCategories;
    };

    const evaluationQuestionCategories = createEvaluationQuestionCategories(assessmentDetail);

    const challengesAndOfferings = evaluationQuestionCategories.map((category) => {
        // Find the matching category based on question_category_id
        const matchedCategory = assessmentDetail.category_analysis_notes.find(
            (note) => note.question_category_id === category.id
        );
        return (
            <ChallengesAndOpportunitiesComponent
                key={category.id.toString()}
                title={category.category}
                challengesThreats={matchedCategory?.challenges_threats || []}
                possibleOfferings={matchedCategory?.possible_offerings || []}
            />
        );
    });

    function calculateCombinedMaxScoresWithAverage(assessmentDetail: AssessmentOverviewDetails): QuestionCategoryResult {
        // Initialize accumulators for total questions, max score, and average score
        let totalQuestions = 0;
        let totalMaxCumulativeScore = 0;
        let totalCumulativeAverageScore = 0;

        const questionCategoryMap: Record<number, string> = {};

        // Iterate through all question categories
        assessmentDetail.assessment_definition.question_categories.forEach(category => {
            const numberOfQuestions = category.assessment_questions.length;
            totalQuestions += numberOfQuestions;

            let categoryMaxScore = 0;

            // Calculate the max score for each question in the category
            category.assessment_questions.forEach(question => {
                if (question.question_definition.is_scorable) {
                    const highestScore = question.question_definition.possible_answers?.reduce((max, answer) => {
                        const score = parseFloat(answer.score);
                        return score > max ? score : max;
                    }, 0) ?? 0;

                    categoryMaxScore += highestScore;
                }
            });

            totalMaxCumulativeScore += categoryMaxScore;

            // Map the question ID to the category name for score calculation later
            category.assessment_questions.forEach(question => {
                questionCategoryMap[question.id] = category.name;
            });
        });

        // Now, calculate the cumulative average score for all categories
        assessmentDetail.assessment_scores.forEach(scoreEntry => {
            totalCumulativeAverageScore += scoreEntry.average_score;
        });

        // Apply toPrecision(12) to avoid floating-point artifacts
        totalMaxCumulativeScore = Number(totalMaxCumulativeScore.toPrecision(12));
        totalCumulativeAverageScore = Number(totalCumulativeAverageScore.toPrecision(12));

        // Return the combined result
        return {
            name: "Total Company Average",
            number_of_questions: totalQuestions,
            max_cumulative_score: totalMaxCumulativeScore,
            cumulative_average_score: totalCumulativeAverageScore,
        };
    }

    function calculateMaxScoresWithAverage(assessmentDetail: AssessmentOverviewDetails): QuestionCategoryResult[] {
        const results: QuestionCategoryResult[] = [];

        // Create a mapping from question ID to its corresponding category
        const questionCategoryMap: Record<number, string> = {};

        assessmentDetail.assessment_definition.question_categories.forEach(category => {
            category.assessment_questions.forEach(question => {
                // Map the question ID to the category name
                questionCategoryMap[question.id] = category.name;
            });
        });

        // Initialize the result array with categories and their max scores
        assessmentDetail.assessment_definition.question_categories.forEach(category => {
            const numberOfQuestions = category.assessment_questions.length;
            let cumulativeScore = 0;

            category.assessment_questions.forEach(question => {
                if (question.question_definition.is_scorable) {
                    const highestScore = question.question_definition.possible_answers?.reduce((max, answer) => {
                        const score = parseFloat(answer.score);
                        return score > max ? score : max;
                    }, 0) ?? 0;

                    cumulativeScore += highestScore;
                }
            });

            // Add each category to the result
            results.push({
                name: category.name,
                id: category.id,
                number_of_questions: numberOfQuestions,
                max_cumulative_score: Number(cumulativeScore.toPrecision(12)),
                cumulative_average_score: 0
            });
        });

        // Now, calculate the cumulative average score for each category based on assessment_scores
        assessmentDetail.assessment_scores.forEach(scoreEntry => {
            const categoryName = questionCategoryMap[scoreEntry.assessment_question_id];

            // Find the category result to update
            const categoryResult = results.find(result => result.name === categoryName);

            if (categoryResult && typeof categoryResult.cumulative_average_score === 'number') {
                // Ensure we're working with numbers during calculations
                categoryResult.cumulative_average_score += scoreEntry.average_score;
            }
        });

        // Apply toPrecision(12) to cumulative_average_score to avoid floating-point artifacts
        results.forEach(result => {
            result.cumulative_average_score = Number((result.cumulative_average_score as number).toPrecision(12));  // Preserve precision
        });

        return results;
    }

    const cumulativeBarChart = React.useMemo(() => {
        // Iterate through the results from calculateMaxScoresWithAverage
        const questionCategoryResult = calculateCombinedMaxScoresWithAverage(assessmentDetail);

        return (
            <Grid
                item
                xs={12}
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '1em 0', gap: '2em' }}
                key={questionCategoryResult.name}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5em' }}>
                    <div style={{
                        color: '#6A6A6A',
                        fontFamily: 'Inter',
                        fontSize: '1em',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '27.233px',
                        letterSpacing: '-0.424px',
                    }}>
                        {questionCategoryResult.name}
                    </div>
                    <div style={{
                        display: 'flex',
                        padding: '3.026px 15.13px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1em',
                        borderRadius: '6.052px',
                        background: '#EFF7FB',
                    }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '0.8em',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.303px',
                        }}>
                            {questionCategoryResult.number_of_questions} Questions
                        </div>
                    </div>
                </div>

                <BarChart
                    cumulative_average_score={Number(questionCategoryResult.cumulative_average_score)}
                    max_cumulative_score={Number(questionCategoryResult.max_cumulative_score)}
                    bar_height='51px'
                    bar_width='650px'
                    bar_color='#053d58'
                />
            </Grid>
        );
    }, [assessmentDetail]);

    const barCharts = React.useMemo(() => {
        // Iterate through the results from calculateMaxScoresWithAverage
        const questionCategoryResults = calculateMaxScoresWithAverage(assessmentDetail);

        return questionCategoryResults.map((questionCategoryResult, index) => (
            <Grid
                item
                key={questionCategoryResult.name}
                sm={12}
            >
                {index !== 0 && <hr style={{
                    border: 'none', borderTop: '2px solid #2FA9E3', width: '90%', marginLeft: '5%'
                }}
                />}
                <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: '1em 5% 1em 5%' }}
                >
                    {/* Label portion of each category row  */}
                    <div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                        <div style={{
                            color: '#6A6A6A',
                            fontFamily: 'Inter',
                            fontSize: '1em',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '27.233px',
                            letterSpacing: '-0.424px',
                        }}>
                            {questionCategoryResult.name}
                        </div>
                        <div style={{
                            display: 'flex',
                            padding: '3.026px 15.13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '1em',
                            borderRadius: '6.052px',
                            background: '#EFF7FB',
                        }}>
                            <div style={{
                                color: '#6A6A6A',
                                fontFamily: 'Inter',
                                fontSize: '0.8em',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '27.233px',
                                letterSpacing: '-0.303px',
                            }}>
                                {questionCategoryResult.number_of_questions} Questions
                            </div>
                        </div>
                    </div>

                    {/* BarChart portion of each category row  */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                    }}
                    >
                        <BarChart
                            cumulative_average_score={Number(questionCategoryResult.cumulative_average_score)}
                            max_cumulative_score={Number(questionCategoryResult.max_cumulative_score)}
                        />
                    </div>
                </div>
            </Grid >
        ));
    }, [assessmentDetail]);

    return (
        <Grid
            container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '25px',
                    right: '25px',
                }}
            >
                {(assessmentDetail.response_count > 0) && (
                    <SaveButton
                        sx={{
                            paddingX: 2,
                            fontSize: '18px'
                        }}
                        onClick={() => {
                            store.dispatch(clearAssessmentDetails());
                            navigate(
                                `/evaluation-assessment-detail/${id || ''}/evaluation-assessment-analysis-score`,
                            );
                        }}
                    >
                        {'Report Builder'}
                    </SaveButton>
                )}
            </Box>

            <Box sx={{ marginTop: '2em' }}>
                <Grid
                    item
                    sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                        minHeight: '200px',
                        margin: '2em 0',
                        padding: '20px',
                        width: '100%',
                        // disply: 'flex',
                        // justifyContent: 'center',
                        // alignItems: 'center'
                    }}
                >
                    <Typography19px600>{'Current State Summary'}</Typography19px600>
                    <Typography12px400
                        sx={{
                            color: '#B5B5C3',
                            marginBottom: '20px'
                        }}
                    >
                        {'Overview of the total company average'}
                    </Typography12px400>
                    <Grid container justifyContent="center" alignItems='center'>
                        {cumulativeBarChart}
                    </Grid>
                </Grid>

                <Grid container spacing={1} justifyContent="center" alignContent="space-around">
                    {challengesAndOfferings}
                </Grid>

                <Grid
                    item
                    marginBottom={'70px'}
                    sx={{
                        borderRadius: '10px',
                        background: '#FFF',
                        boxShadow: '0px 10px 20px 0px rgba(194, 194, 194, 0.16)',
                        minHeight: '200px',
                        margin: '10px 0',
                        padding: '20px',
                        width: '100%',
                    }}
                >
                    <Typography19px600>{'Survery Results by Category'}</Typography19px600>
                    <Typography12px400
                        sx={{
                            color: '#B5B5C3',
                            marginBottom: '20px'
                        }}
                    >
                        {'Brief overview of results by category'}
                    </Typography12px400>
                    <Grid container justifyContent="center" alignItems="center">
                        {barCharts}
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
};

export default AssessmentAnalysis;