import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import AssessmentCard from './AssessmentCard';
import { useNavigate } from 'react-router-dom';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import useGetAssessments from '../../hooks/useGetAssessments';
import useDeleteEvaluation from '../../../Evaluations/hooks/useDeleteEvaluation';
import ConfirmationBox from '../../../../components/ConfirmationBox/ConfirmBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootStoreType } from '../../../../redux/store.type';
import { AssessmentListDetail } from '../../type';
import useDeleteAssessment from '../../hooks/useDeleteAssessment'


const AssessmentList = () => {
  const [assessments, setAssessments] = useState<AssessmentListDetail[]>([]); // Store all assessments
  const [filteredAssessments, setFilteredAssessments] = useState<AssessmentListDetail[]>([]); // Store filtered assessments
  const [searchText, setSearchText] = useState('');
  const [statusType, setStatusType] = useState('');
  const [visibleItems, setVisibleItems] = useState(10); // How many items to display at first
  const [loadingMore, setLoadingMore] = useState(false);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  //TODO: This needs to be updated to be V2 Delete Assessment when it is made
  const { loading: deleteLoading, success: deleteSuccess, deleteAssessmentById } = useDeleteAssessment();
  const [openConfirmBox, SetOpenConfirmBox] = useState(false);
  const [deleteEvaluationId, setDeleteEvaluationId] = useState('');
  const { error, loading, getAllAssessments: getAllAssessments } = useGetAssessments();

  const freshAssessments = useSelector(
    (state: RootStoreType) =>
      state.assessmentReducers.getAssessmentsReducer
        .assessments as AssessmentListDetail[],
  );

  useEffect(() => {
    if (freshAssessments) {
      setAssessments(freshAssessments); // Set the assessments from the Redux store
      setFilteredAssessments(freshAssessments); // Optionally update the filtered assessments
    }
  }, [freshAssessments]);

  useEffect(() => {
    getAllAssessments();
  }, []);

  useEffect(() => {
    if (!deleteLoading && deleteSuccess) {
      getAllAssessments();
    }
  }, [deleteLoading, deleteSuccess]);

  function handleDelete(id: string) {
    SetOpenConfirmBox(true);
    setDeleteEvaluationId(id);
  }

  useEffect(() => {
    const filtered = assessments.filter((assessment) => {
      const matchesSearch =
        assessment.name.toLowerCase().includes(searchText.toLowerCase()) ||
        assessment.account.name
          .toLowerCase()
          .includes(searchText.toLowerCase());

      const matchingExecs = assessment.account.account_executives.some((exec) =>
        exec.fullname ? exec.fullname.toLowerCase().includes(searchText.toLowerCase()): false,
      );

      const combinedMatches = matchesSearch || matchingExecs;

      const matchesStatus =
        statusType === '' ||
        (Array.isArray(statusType)
          ? statusType.includes(assessment.readable_status)
          : assessment.readable_status === statusType);

      return combinedMatches && matchesStatus;
    });
    setFilteredAssessments(filtered);
    setVisibleItems(8);
  }, [searchText, statusType, assessments]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 100 && !loadingMore) {
        loadMoreItems();
      }
    }
  };

  const loadMoreItems = () => {
    if (visibleItems < filteredAssessments.length) {
      setLoadingMore(true);
      setTimeout(() => {
        setVisibleItems((prev) => prev + 8);
        setLoadingMore(false);
      }, 1000); //should we fake "load" on infinite scrolly boy
    }
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filteredAssessments, visibleItems, loadingMore]);

  return (
    <Box
      sx={{ maxWidth: '1540px', overflowY: 'auto', height: '90vh' }}
      ref={scrollRef}
    >
      <Grid container sx={{ justifyContent: 'flex-end', marginTop: '1%' }}>
        <Grid item sm={2} sx={{ marginLeft: '2%' }}>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ marginBottom: '1.5%' }}
          />
        </Grid>
        <Grid item sm={2} sx={{ marginLeft: '1%' }}>
          <Select
            value={statusType}
            onChange={(e) => setStatusType(e.target.value)}
            displayEmpty
            fullWidth
            sx={{ marginBottom: '1.5%' }}
          >
            {/* //Need to come back for these enums after finding real values */}
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="Draft">Draft</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Results Review">Results Review</MenuItem>
            <MenuItem value="Closed" >Closed</MenuItem>
          </Select>
        </Grid>

        <Grid
          item
          sm={2}
          sx={{
            marginLeft: 'auto',
            marginY: 'auto',
            justifyContent: 'flex-end',
            display: 'flex',
            marginRight: '1%',
          }}
        >
          <SaveButton onClick={() => navigate(`/create-evaluation-assessment`)}>
            Create Assessment
          </SaveButton>
        </Grid>
      </Grid>
      {filteredAssessments.length === 0 ? (
        <Box sx={{ textAlign: 'center', marginTop: '5%' }}>
          <Typography variant="h6" color="textSecondary">
            No assessments currently created.
          </Typography>
        </Box>
      ) : (
        filteredAssessments.slice(0, visibleItems).map((assessment) => (
          <Box
            key={assessment.id}
            sx={{ paddingBottom: '1.5%', cursor: 'pointer' }}
            onClick={() =>
              navigate(`/evaluation-assessment-detail/${assessment.uuid}`)
            }
          >
            <AssessmentCard
              assessmentDetails={assessment}
              deleteAssessment={handleDelete}
            />
          </Box>
        ))
      )}

      {openConfirmBox && (
        <ConfirmationBox
          doOpen={openConfirmBox}
          deleteRecord={() => {
            deleteAssessmentById(deleteEvaluationId);
          }}
          doClose={() => {
            SetOpenConfirmBox(false);
          }}
          confirmationMessage="Respondents will not be able to submit responses after it has been deleted."
        />
      )}

      {loadingMore && (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default AssessmentList;
