import React from 'react';

interface BarChartProps {
  cumulative_average_score: number;
  max_cumulative_score: number;
  bar_height?: string;
  bar_width?: string;
  bar_color?: string;
}

const BarChart: React.FC<BarChartProps> = ({
  cumulative_average_score,
  max_cumulative_score,
  bar_height = '30px',   // Default height
  bar_width = '300px',   // Default width
  bar_color = '#2FA9E3'  // Default bar color

}) => {
  // Calculate percentage values for the bar chart
  const progressPercentage = (cumulative_average_score / max_cumulative_score) * 100;

  return (
    <div style={containerStyle}>
      {/* The bar container */}
      <div style={{...wholeBarStyle, width: bar_width, height: bar_height }}>
        {/* The blue progress bar */}
        <div style={{ ...progressBarStyle, width: `${progressPercentage}%`, backgroundColor: bar_color }}>
          <span style={labelStyle}>{`${cumulative_average_score.toFixed(2)}%`}</span>
        </div>
      </div>

      {/* The max value label outside the bar */}
      <span style={maxLabelStyle}>{`${max_cumulative_score}%`}</span>
    </div>
  );
};

const containerStyle: React.CSSProperties = {
  display: 'flex',        // Flexbox layout for side-by-side bar and max label
  alignItems: 'center',   // Align vertically centered
  // justifyContent: 'start',
  minWidth: '375px',         // Full width including the space for max label
  position: 'relative',
  // marginRight: '5em'   // Relative positioning for the container
};

// Correctly typed styles
const wholeBarStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#B3B3B3', // Light gray background for the bar
  overflow: 'hidden',
  position: 'relative',   // Relative positioning for the container
};

const progressBarStyle: React.CSSProperties = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',               // Text color inside the bar
  fontWeight: 'bold',
};

const labelStyle: React.CSSProperties = {
  padding: '0 10%',
  fontWeight: '900',
  color: '#FFF',
  fontFamily: "Inter",
  fontSize: "19.669px",
  fontStyle: "normal",
  lineHeight: "20.2px",
  letterSpacing: "-0.393px",
};

const maxLabelStyle: React.CSSProperties = {
  marginLeft: '5px',
  color: '#053D58',
  fontFamily: 'Inter',
  fontSize: '20.191px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20.2px',
  letterSpacing: '-0.404px',
};

export default BarChart;
