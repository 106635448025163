import React, {  CSSProperties } from 'react';


const MenuHelper  = {
    PaperProps: {
      style: {
        maxHeight: 48 * 8, // 48px is the default height of a MenuItem, showing 8 items
        overflowY: 'auto' as CSSProperties['overflowY'], // Enables scrolling for items beyond 8
      }
    }
  };

export default MenuHelper;
