import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useParams } from 'react-router';
import { useAppSelector } from '../../../../redux/store';
import BarChartIcon from '@mui/icons-material/BarChart';
import { SaveButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import useGetAssessmentDetails from '../../hooks/useGetAssessmentDetails';
import { clearAssessmentDetails } from '../../reducers/getAssessmentDetails';
import { AssessmentQuestionCategory, AssessmentOverviewDetails, QuestionCategoryResult, AssessmentNotesPayload } from '../../type'
import { CustomAccountBranding } from '../../../Branding/type';
import CategoryScoreDetailsTab from './CategoryScoreDetailsTab'
import AssessmentAnalysisNotes from '../../AssessmentDetails/components/Analysis/AssessmentAnalysisNotes';
import useAssessmentNotes from '../../hooks/usePutAssessmentNotes';

interface CategoryAnalysisContainerProps {
    branding: CustomAccountBranding;
    id: string;
}

const CategoryAnalysisContainer: React.FC<CategoryAnalysisContainerProps> = ({ branding, id }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [value, setValue] = useState<string>('1');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedCategory, setSelectedCategory] = useState<string>('');
    const [modalOpen, setModalOpen] = useState(false);
    const [questionCategorySurveyResults, setQuestionCategorySurveyResults] = useState<QuestionCategoryResult[]>([]);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setCurrentIndex(0);
    };

    const getAssessmentDetailsReducer = useGetAssessmentDetails();

    const assessmentDetail = useAppSelector((state) => {
        return state.assessmentReducers.getAssessmentDetailsReducer?.assessment_details;
    });

    React.useEffect(() => {
        dispatch(clearAssessmentDetails());
        getAssessmentDetailsReducer.getDetails(id);
    }, [id]);

    React.useEffect(() => {
        getAssessmentDetailsReducer.getDetails(id);
    }, []);

    React.useEffect(() => {
        if (assessmentDetail) {
            setQuestionCategorySurveyResults(calculateMaxScoresWithAverage(assessmentDetail));
        }
    }, [assessmentDetail]);

    const { updateAssessmentNotes } = useAssessmentNotes();

    const onNotesSubmit = (data: AssessmentNotesPayload) => { 
        const defaultValues = {
            assessment_id: assessmentDetail?.uuid
        };
        updateAssessmentNotes({
            ...defaultValues,
            ...data,
        });
        getAssessmentDetailsReducer.getDetails(id);
    };

    function calculateMaxScoresWithAverage(assessmentDetail: AssessmentOverviewDetails): QuestionCategoryResult[] {
        const results: QuestionCategoryResult[] = [];

        // Create a mapping from question ID to its corresponding category
        const questionCategoryMap: Record<number, string> = {};

        assessmentDetail.assessment_definition.question_categories.forEach(category => {
            category.assessment_questions.forEach(question => {
                // Map the question ID to the category name
                questionCategoryMap[question.id] = category.name;
            });
        });

        // Initialize the result array with categories and their max scores
        assessmentDetail.assessment_definition.question_categories.forEach(category => {
            const numberOfQuestions = category.assessment_questions.length;
            let cumulativeScore = 0;

            category.assessment_questions.forEach(question => {
                if (question.question_definition.is_scorable) {
                    const highestScore = question.question_definition.possible_answers?.reduce((max, answer) => {
                        const score = parseFloat(answer.score);
                        return score > max ? score : max;
                    }, 0) ?? 0;

                    cumulativeScore += highestScore;
                }
            });

            // Add each category to the result
            results.push({
                name: category.name,
                id: category.id,
                number_of_questions: numberOfQuestions,
                max_cumulative_score: Number(cumulativeScore.toPrecision(12)),
                cumulative_average_score: 0
            });
        });

        // Now, calculate the cumulative average score for each category based on assessment_scores
        assessmentDetail.assessment_scores.forEach(scoreEntry => {
            const categoryName = questionCategoryMap[scoreEntry.assessment_question_id];

            // Find the category result to update
            const categoryResult = results.find(result => result.name === categoryName);

            if (categoryResult && typeof categoryResult.cumulative_average_score === 'number') {
                // Ensure we're working with numbers during calculations
                categoryResult.cumulative_average_score += scoreEntry.average_score;
            }
        });

        // Apply toPrecision(12) to cumulative_average_score to avoid floating-point artifacts
        results.forEach(result => {
            result.cumulative_average_score = Number.isInteger((result.cumulative_average_score as number)) ? (result.cumulative_average_score as number) : parseFloat((result.cumulative_average_score as number).toFixed(2));  // Preserve precision
        });

        return results;
    }

    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '20px',
                }}
            >
                <SaveButton
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    Back
                </SaveButton>
            </Box>
            {!assessmentDetail ||
                !questionCategorySurveyResults ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) : (
                assessmentDetail &&
                questionCategorySurveyResults && (
                    <TabContext value={value}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                            <TabList
                                sx={{
                                    flexWrap: 'wrap', // Allow the tabs to wrap into multiple rows
                                    '& .MuiTabs-flexContainer': {
                                        flexWrap: 'wrap', // Ensure the tab container wraps as well
                                    },
                                    '& .MuiTab-root': {
                                        textTransform: 'none',    // Disable the uppercase transformation
                                        // minWidth: '150px',        // Set a minimum width for each "button"
                                        fontSize: '0.75em',
                                        padding: '0em 0.5em',      // Padding to make them look like buttons
                                        margin: '0px 5px 0px 5px',            // Add spacing between the tabs
                                        borderRadius: '5px',   // Rounded corners for button look
                                        borderBottomLeftRadius: '0px',
                                        borderBottomRightRadius: '0px',
                                        border: '1px solid #f4f4f4', // Border to give a button-like appearance
                                        borderBottom: 'none',
                                        backgroundColor: '#ffffff', // Light background to mimic button
                                        color: '#333',            // Text color
                                        '&:hover': {
                                            backgroundColor: '#fbfbfb', // Slightly darker background on hover
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: branding.primary_color, // Button-like background for selected tab
                                            color: 'white',             // White text on selected tab
                                            border: `1px solid ${branding.primary_color}`,     // Border to match the selected background
                                        },
                                    },
                                }}
                                onChange={handleChange}
                                TabIndicatorProps={{
                                    style: { display: 'none' }, // Hide the default indicator
                                }}
                                aria-label="styled tabs as buttons"
                            >
                                {assessmentDetail.assessment_definition.question_categories.map(
                                    (category: AssessmentQuestionCategory) => (
                                        <Tab
                                            key={category.id}
                                            label={category.name}
                                            value={category.id.toString()}
                                        />
                                    ))}
                                <Tab label="Notes" value="notes" />
                            </TabList>
                        </Box>

                        {assessmentDetail.assessment_definition.question_categories.map(
                            (category: AssessmentQuestionCategory) => (
                                <TabPanel
                                    sx={{ padding: '0' }}
                                    value={category.id.toString()}
                                    key={category.id}
                                >
                                    <CategoryScoreDetailsTab
                                        branding={branding}
                                        assessmentDetail={assessmentDetail}
                                        assessmentCategoryDetail={category}
                                        categoryAnalysisNotes={assessmentDetail.category_analysis_notes.find((note) => note.question_category_id === category.id)}
                                        assessmentScores={assessmentDetail.assessment_scores}
                                        questionCategorySurveyResult={questionCategorySurveyResults.find(
                                            (item) => item.id === category.id)}
                                    />
                                </TabPanel>
                            ),
                        )}
                        <TabPanel value="notes">
                            <AssessmentAnalysisNotes
                                initialAssessmentNotes={assessmentDetail.assessment_notes[0]}
                                onNotesSubmit={onNotesSubmit}
                            />
                        </TabPanel>
                    </TabContext>
                )
            )}

            {/* <IndividualResponseModal
                open={modalOpen}
                handleClose={handleCloseModal}
                evaluationId={id || ''}
                questionCategoryId={value || ''}
                questionCategory={selectedCategory || ''}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
            /> */}
        </div>
    );
};

export default CategoryAnalysisContainer;
