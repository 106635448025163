import { combineReducers } from '@reduxjs/toolkit';
import { AssessmentDetailsReducer, PublicAssessmentDetailsReducer, AssessmentDefinitionsReducer, AssessmentCreationReducer } from '../type';
import getAssessmentDetailsReducer from './getAssessmentDetails';
import getPublicAssessmentDetailsReducer from './getPublicAssessmentDetails';
import getAssessmentsReducer from './getAssessments';
import getCreatedAssessmentReducer from './postAssessment';
import { AssessmentsReducer } from './getAssessments';
import accountInfoReducer, { AccountDetailInitialState } from '../../Assessments/reducers/getAccount';
import getAssessmentDefinitionsReducer from './getAssessmentDefinitions';

export default combineReducers<{
    getAssessmentDetailsReducer: AssessmentDetailsReducer;
    getPublicAssessmentDetailsReducer: PublicAssessmentDetailsReducer;
    getAssessmentsReducer: AssessmentsReducer;
    accountInfoReducer: AccountDetailInitialState;
    getAssessmentDefinitionsReducer: AssessmentDefinitionsReducer;
    getCreatedAssessmentReducer : AssessmentCreationReducer;
}>({
    getAssessmentDetailsReducer,
    getPublicAssessmentDetailsReducer,
    getAssessmentsReducer,
    accountInfoReducer,
    getAssessmentDefinitionsReducer,
    getCreatedAssessmentReducer
});
