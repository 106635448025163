export const EndPoints = {
  GET_USER_SUMMARY: `/users/summary`,
  POST_CREATE_CLIENT: `/account/`,
  POST_LOG_IN: `/login/`,
  GET_CLIENT_LIST: `/account/`,
  POST_ACTIVATE_PASSWORD: `/activate-account/`,
  POST_FORGET_PASSWORD: `/forgot-password/`,
  POST_LOGOUT: `/logout/`,
  POST_EVALUATION_LINK: `evaluate/resend-email/`,
  GET_ALL_USERS: `users/`,
  GET_EVALUATION_QUESTIONS: `evaluate/`,
  GET_EVALUATION_CATEGORIES: `evaluation-categories/`,
  GET_SHAREABLE_LINK: `generate-uuid`,
  POST_EVALUATION: `evaluate/`,
  GET_EVALUATION_LIST: `evaluate/list/`,
  ACCOUNT_SETTING: `account-settings/`,
  EVALUATION_DETAIL: `evaluate/list/`,
  GET_CLIENT_ACCOUNT: `/account/`,
  DELETE_ACCOUNT: `/account/`,
  DELETE_EVALUATION: `/evaluate/`,
  PUT_UPDATE_ACCOUNT: `/account/`,
  POST_EVALUATION_SURVEY: `evaluate/submit-response/`,
  POST_EVALUATION_RESULT_MC_ANSWERS: `/evaluate/result/answers/multiplechoice`,
  POST_EVALUATION_RESULT_TEXT_ANSWERS: `/evaluate/result/answers/textentry`,
  POST_EVALUATION_RESULT_ANALYSIS: `/evaluate/result/analysis/`,
  POST_EVALUATION_RESPONSE_BREAKDOWN: `/evaluation/response/breakdown/`,
  POST_EVALUATION_SCORE_HISTORY: `evaluation/score/history/`,
  PUT_EVALUATION_RESULT_ANALYSIS: `/evaluate/result/analysis/notes/`,
  EVALUATION_NOTES: `evaluate/result/notes/`,
  GET_EVALUATION_QUESTION_CATEGORIES: `evaluation-question-categories`,
  GET_EVALUATION_REPORT: `/evaluate/report/`,
  GET_EVALUATION_NAME: `/evaluate/name/`,
  POST_CHANGE_EVALUATION_STATUS: `evaluate/status/change/`,
  POST_EVALUATION_CATEGORIES_RESULT_ANALYSIS: `/evaluation-categories/result/analysis/`,
  GET_CLOUDINARY_SIGNATURE: `/cloudinary/signature/`,
  POST_EVALUATION_IMAGE_URL: `/evaluation/imagedetails/`,
  SCOIN_SUPPLIER: `/scoin/supplier/`,
  SCOIN_NAICS_CODE: `scoin/naics_code/`,
  //V2
  ASSESSMENT: `assessment`,
  ASSESSMENT_DEFINITIONS: `assessment/definition`,
  ASSESSMENT_STATUS_CHANGE: `assessment/statuschange`,
  ASSESSMENT_CATEGORY_ANALYSIS_NOTE: `assessment/categoryanalysisnote`,
  ASSESSMENT_NOTES: `assessment/note`,
  //V2 Public
  PUBLIC_ASSESSMENT: `public/assessment`,
  PUBLIC_ASSESSMENT_RESPONSE: `public/assessment/response`,
  PUBLIC_ASSESSMENT_DEFINITIONS: `public/assessment/definition`,
};
