import React, { useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField,
    CircularProgress,
    Button,
    useTheme,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import {
    FieldName,
    FieldValue,
    GridItemFieldName,
    GridItemFieldValue,
} from '../../../Assessments/AssessmentDetails/components/EvaluationDetails.style';
import { PieChart, Pie, Cell } from 'recharts';
import {
    CustomButton,
    Typography10px400,
    Typography12px400,
    Typography14px400,
    Typography14px600,
} from '../../../../styles/Global.styles';
import { useForm, FieldValues, Controller } from 'react-hook-form';
import translation from '../../../../locales/en/translation.json';
import translate from '../../../../locales/translate';
import {
    FormFieldContainer,
    SaveButton
} from '../../../../modules/Accounts/CreateClient/CreateClient.styles';
import BarChart from '../../AssessmentDetails/components/Analysis/BarChart';


import { CustomAccountBranding } from '../../../Branding/type';
import {
    AssessmentQuestionCategory, AssessmentOverviewDetails, CategoryAnalysisNote,
    CategoryAnalysisNotePayload, AssessmentScore, QuestionCategoryResult,
    ScorableQuestionAndAnswer, QuestionType
} from '../../type'
import { categoryNoteFields } from '../../Constants/AssessmentAnalysisNotes'
import CategoryAnalysisNotesField from './CategoryAnalysisNotesField';
import useGetAssessmentDetails from '../../hooks/useGetAssessmentDetails';
import usePutCategoryAnalysisNote from '../../hooks/usePutCategoryAnalysisNote';
import MultipleChoiceScoreLineChart from './MultipleChoiceScoreLineChart';

interface CategoryScoreDetailsTabProps {
    branding: CustomAccountBranding;
    assessmentDetail: AssessmentOverviewDetails;
    assessmentCategoryDetail: AssessmentQuestionCategory;
    categoryAnalysisNotes: CategoryAnalysisNote | undefined;
    assessmentScores: AssessmentScore[];
    questionCategorySurveyResult: QuestionCategoryResult | undefined;
}

const placeholderText = {
    color: '#B5B5C3',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '5px',
};

const innerText = {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '5px',
};

const CategoryScoreDetailsTab: React.FC<CategoryScoreDetailsTabProps> = ({ branding, assessmentDetail, assessmentCategoryDetail, categoryAnalysisNotes, assessmentScores, questionCategorySurveyResult }) => {
    const theme = useTheme();

    const [allQuestionAndMCAnswers, setAllQuestionAndMCAnswers] = useState<ScorableQuestionAndAnswer[]>([]);
    const [mcQuestionAndAnswers, setMcQuestionAndAnswers] = useState<ScorableQuestionAndAnswer[]>([]);
    const [numericQuestionAndAnswers, setNumericQuestionAndAnswers] = useState<ScorableQuestionAndAnswer[]>([]);


    const getAssessmentDetailsReducer = useGetAssessmentDetails();
    const { updateCategoryAnalysisNote } = usePutCategoryAnalysisNote();

    function getQuestionAndMCAnswerArray(assessmentCategoryDetail: AssessmentQuestionCategory, assessmentScores: AssessmentScore[]): ScorableQuestionAndAnswer[] {
        const result: ScorableQuestionAndAnswer[] = [];

        assessmentCategoryDetail.assessment_questions.forEach(question => {
            const { question_definition } = question;

            // Check if the question is scorable
            if (question_definition.is_scorable) {
                const possibleAnswers = question_definition.possible_answers;

                if (possibleAnswers && possibleAnswers.length > 0) {
                    // Extract lowest and highest scores
                    const lowestScore = possibleAnswers.reduce((min, answer) =>
                        Math.min(min, parseFloat(answer.score)), Infinity).toString();
                    const highestScore = possibleAnswers.reduce((max, answer) =>
                        Math.max(max, parseFloat(answer.score)), -Infinity).toString();

                    // Calculate the average score from assessment_scores
                    const scoreDetails = assessmentScores.find(
                        score => score.assessment_question_id === question.id
                    );
                    const averageResponseScore = scoreDetails ? scoreDetails.average_score : 0;

                    // Push the question data into the result array
                    result.push({
                        question_id: question.id,
                        question_text: question.text,
                        question_type: question_definition.question_type,
                        question_lowest_score: lowestScore,
                        question_highest_score: highestScore,
                        average_response_score: averageResponseScore
                    });
                }
            }
        });

        return result;
    }

    React.useEffect(() => {
        setAllQuestionAndMCAnswers(getQuestionAndMCAnswerArray(assessmentCategoryDetail, assessmentScores));
    }, [assessmentCategoryDetail, assessmentScores]);

    React.useEffect(() => {
        setMcQuestionAndAnswers(allQuestionAndMCAnswers.filter((eachQuestionObj) => eachQuestionObj.question_type === QuestionType.MULTIPLE_CHOICE));
    }, [allQuestionAndMCAnswers]);

    React.useEffect(() => {
        setNumericQuestionAndAnswers(allQuestionAndMCAnswers.filter((eachQuestionObj) => eachQuestionObj.question_type === QuestionType.NUMERIC || eachQuestionObj.question_type === QuestionType.NUMERIC_RANGE));
    }, [allQuestionAndMCAnswers]);

    const cumulativeBarChart = React.useMemo(() => {
        if (questionCategorySurveyResult) {

            return (
                <Grid
                    item
                    xs={12}
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '1em 0', gap: '2em' }}
                    key={questionCategorySurveyResult.name}
                >

                    <BarChart
                        cumulative_average_score={Number(questionCategorySurveyResult.cumulative_average_score)}
                        max_cumulative_score={Number(questionCategorySurveyResult.max_cumulative_score)}
                        bar_height='51px'
                        bar_width='90%'
                        bar_color='#053d58'
                    />
                </Grid>
            );
        }
    }, [assessmentDetail]);

    const resultAnalysisForm = useForm<FieldValues & CategoryAnalysisNotePayload>({
        defaultValues: {
            assessment_id: assessmentDetail.uuid,
            question_category_id: assessmentCategoryDetail.id,
            strengths: [],
            strengths_string: '',
            challenges_threats: [],
            challenges_threats_string: '',
            root_causes: [],
            root_causes_string: '',
            possible_offerings: [],
            possible_offerings_string: '',
            go_forward_plan: '',
        },
    });

    const {
        register,
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = resultAnalysisForm;

    React.useEffect(() => {
        if (categoryAnalysisNotes) {
            const strengthsValuesString =
                categoryAnalysisNotes.strengths?.join('\n');
            const challengesThreatsValuesString =
                categoryAnalysisNotes.challenges_threats?.join('\n');
            const rootCausesValuesString =
                categoryAnalysisNotes.root_causes?.join('\n');
            const possibleOfferingsValuesString =
                categoryAnalysisNotes.possible_offerings?.join('\n');

            setValue('strengths_string', strengthsValuesString);
            setValue('challenges_threats_string', challengesThreatsValuesString);
            setValue('root_causes_string', rootCausesValuesString);
            setValue('possible_offerings_string', possibleOfferingsValuesString);
            setValue('go_forward_plan', categoryAnalysisNotes.go_forward_plan);

        }
    }, [categoryAnalysisNotes, setValue]);

    const onSubmit = (data: CategoryAnalysisNotePayload) => {
        data.strengths = (data.strengths_string && data.strengths_string.length > 0)
            ? data.strengths_string?.split('\n').filter((i) => i)
            : (data.strengths_string == '') ? [] : undefined;
        data.strengths_string = undefined;

        data.challenges_threats = (data.challenges_threats_string && data.challenges_threats_string.length > 0)
            ? data.challenges_threats_string?.split('\n').filter((i) => i)
            : (data.challenges_threats_string == '') ? [] : undefined;
        data.challenges_threats_string = undefined;

        data.root_causes = (data.root_causes_string && data.root_causes_string.length > 0)
            ? data.root_causes_string?.split('\n').filter((i) => i)
            : (data.root_causes_string == '') ? [] : undefined;
        data.root_causes_string = undefined;

        data.possible_offerings = (data.possible_offerings_string && data.possible_offerings_string.length > 0)
            ? data.possible_offerings_string?.split('\n').filter((i) => i)
            : (data.possible_offerings_string == '') ? [] : undefined;
        data.possible_offerings_string = undefined;

        updateCategoryAnalysisNote(data);
        getAssessmentDetailsReducer.getDetails(assessmentDetail.uuid);
    };

    return !assessmentDetail ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
    ) : assessmentCategoryDetail.assessment_questions.length < 1 ? (
        <Grid
            container
            sx={{
                backgroundColor: 'var(--primary-light, #F7F9FB)',
                padding: '20px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Typography14px600>
                {'No question is in this question category.'}
            </Typography14px600>
        </Grid>
    ) : (
        <Grid
            container
            sx={{
                paddingTop: '30px',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Grid item sm={4.7}>
                <Box
                    sx={{
                        width: '100%',
                        backgroundColor: 'var(--primary-light, #F7F9FB)',
                        padding: '30px',
                        borderRadius: '12px',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 600,
                        }}
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        {assessmentCategoryDetail.name}
                    </Typography>

                    <Grid container justifyContent="center" alignItems='center'>
                        {cumulativeBarChart}
                    </Grid>
                </Box>

                <Box
                    sx={{
                        marginX: '10px',
                        marginTop: '10px',
                        marginBottom: '30px',
                    }}
                >
                    <Typography12px400
                        sx={{
                            color: '#181C32',
                            paddingBottom: '5px',
                        }}
                    >
                        {translation.score}: {questionCategorySurveyResult?.cumulative_average_score}
                    </Typography12px400>

                    <Typography10px400
                        sx={{
                            color: '#B5B5C3',
                        }}
                    >
                        {translate('evaluation_score_analysis_score_label_subtext', {
                            responses_received: assessmentDetail.response_count,
                            target_responses: assessmentDetail.target_responses,
                        })}
                    </Typography10px400>
                </Box>







                <form onSubmit={handleSubmit(onSubmit)}>
                    {categoryNoteFields.map((fieldConfig, index) => (
                        <CategoryAnalysisNotesField
                            key={index}
                            control={control}
                            register={register}
                            errors={errors}
                            minRows={fieldConfig.minRows ?? 3}
                            maxRows={fieldConfig.maxRows ?? 8} // Add the maxRows prop here
                            {...fieldConfig}
                            minLength={fieldConfig.minLength ?? 0}
                        />
                    ))}

                    {(
                        <Grid
                            item
                            sm={12}
                            sx={{
                                margin: '30px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <CustomButton
                                type="submit"
                                sx={{
                                    color: '#fff',
                                    lineHeight: '120%',
                                    fontSize: '15px',
                                }}
                            >
                                Save
                            </CustomButton>
                        </Grid>
                    )}
                </form>



            </Grid>

            <Grid item sm={7}>
                {/* <Grid item
                    sx={{
                        display: 'flex',
                        justifyContent: 'center', // Center text in the middle third
                        paddingBottom: '12px',
                    }}> */}
                {/* Wrap Typography and Button in a flex container */}

                {numericQuestionAndAnswers.length > 0 && (
                    <>
                        <Typography12px400
                            sx={{
                                color: '#181C32',
                                paddingTop: '6px',
                                //paddingBottom: '5px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            Question Answer Averages
                        </Typography12px400>
                        {/* </Grid> */}

                        <Grid
                            container
                            sx={{
                                backgroundColor: 'var(--primary-light, #F7F9FB)',
                                padding: '20px',
                                borderRadius: '12px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                            }}
                        >
                            {mcQuestionAndAnswers.map(
                                (eachQuestionObj, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Grid
                                                item
                                                sx={{
                                                    margin: '10px',
                                                }}
                                                sm={7}
                                            >
                                                <Typography12px400
                                                    sx={{
                                                        lineHeight: '20px',
                                                    }}
                                                >
                                                    {eachQuestionObj.question_text}
                                                </Typography12px400>
                                            </Grid>
                                            <Grid item key={i} sm={4}>
                                                <MultipleChoiceScoreLineChart
                                                    value={Math.round(eachQuestionObj.average_response_score * 10) / 10}
                                                    minValue={eachQuestionObj.question_lowest_score as number}
                                                    maxValue={eachQuestionObj.question_highest_score as number}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    );
                                },
                            )}
                        </Grid>
                    </>
                )}



                {numericQuestionAndAnswers.length > 0 && (
                    <>
                        <Typography12px400
                            sx={{
                                color: '#181C32',
                                paddingTop: '30px',
                                paddingBottom: '0px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            Numeric Question Answer Averages
                        </Typography12px400>
                        <Grid
                            container
                            sx={{
                                backgroundColor: 'var(--primary-light, #F7F9FB)',
                                padding: '20px',
                                borderRadius: '12px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                            }}
                        >
                            {numericQuestionAndAnswers.map(
                                (eachQuestionObj, i) => (
                                    <React.Fragment key={i}>
                                        <Grid item sx={{ margin: '10px' }} sm={7}>
                                            <Typography12px400 sx={{ lineHeight: '20px' }}>
                                                {eachQuestionObj.question_text}
                                            </Typography12px400>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <MultipleChoiceScoreLineChart
                                                value={Math.round(eachQuestionObj.average_response_score * 10) / 10}
                                                minValue={eachQuestionObj.question_lowest_score as number}
                                                maxValue={eachQuestionObj.question_highest_score as number}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ),
                            )}
                        </Grid>
                    </>
                )}

                {/* {evaluationTextAnswers.questions_and_answers.length > 0 && (
                    <>
                        <Typography12px400
                            sx={{
                                color: '#181C32',
                                paddingTop: '30px',
                                paddingBottom: '0px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                            }}
                        >
                            Text Entry Question Answers
                        </Typography12px400>
                        <Grid
                            container
                            sx={{
                                backgroundColor: 'var(--primary-light, #F7F9FB)',
                                padding: '20px',
                                borderRadius: '12px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                            }}
                        >
                            {evaluationTextAnswers.questions_and_answers.map(
                                (eachQuestionObj, i) => (
                                    <React.Fragment key={i}>
                                        <Grid item sx={{ margin: '10px' }} sm={7}>
                                            <Typography12px400 sx={{ lineHeight: '20px' }}>
                                                {eachQuestionObj.question_text}
                                            </Typography12px400>
                                        </Grid>
                                        <Grid item key={i} sm={4}>
                                            {eachQuestionObj.answers.map((answer, index) => (
                                                <Box
                                                    key={index}
                                                    sx={{
                                                        maxHeight: '150px',
                                                        overflowY: 'auto',
                                                        border: '1px solid #ccc',
                                                        padding: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    <Typography12px400
                                                        sx={{
                                                            whiteSpace: 'pre-wrap',
                                                            wordWrap: 'break-word',
                                                        }}
                                                    >
                                                        {answer.answer_text}
                                                    </Typography12px400>
                                                </Box>
                                            ))}
                                        </Grid>
                                    </React.Fragment>
                                ),
                            )}
                        </Grid>
                    </>
                )} */}



            </Grid>
        </Grid>
    );
};

export default CategoryScoreDetailsTab;
